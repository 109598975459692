import { WorkType } from '../../types';

import { sendObject } from './handler';

export type ChooseAssignment = {
  type: 'chooseAssignment';
  workType: WorkType;
  workId: number;
};

export const postAssignmentChoice = async (
  workId: number,
  workType: WorkType
): Promise<void> => {
  sendObject({
    type: 'chooseAssignment',
    workId,
    workType,
  });
};

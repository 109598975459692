import $ from 'jquery';
import MainLoop from 'mainloop.js';

import { applyPixelRatioToCanvas, isTouchDevice } from '@gmm/problem';

import { GAME, studentAppModalState } from '../stores/studentAppModalStore';
import { Any, AnyArray, AnyObject } from '../types';

import { gameEnded, gameFinished as gameFinisher } from './game';

type CTX = CanvasRenderingContext2D;

let game: Game;
let ctx: CTX;
let canvas: HTMLCanvasElement;
let time: number;
const games: Record<string, Game> = {};
const w = 300;
// Important: this 'h' is the game area height only, not including header or footer heights.
// The canvas will be bigger, as it will add space for header and possibly footer.
// See resizeCanvas to understand more.
const h = 400;
const topHeight = 30;

let mouseX: number;
let mouseY: number;

// all buttons other than bottom controls
let buttons: BuiltButton[] = [];

let gameLost: boolean | undefined;
let lossMsg: string | undefined;
let tchHighs: HighScores[] | undefined;
let orgHighs: HighScores[] | undefined;
let pauseTimer: Any;
let instructions: string[] | undefined;
// for bug patch
let ranOutOfTime: boolean;

// games played in the current session that have saved data
const gamesPlayed: AnyArray = [];

interface Game {
  bottomControls: BottomControlStates;
  displayName?: string;
  draw: (ctx: CTX) => Any;
  getScore: () => number;
  getState: () => AnyObject;
  instructions: string[];
  keyDown: (event: Partial<JQuery.KeyDownEvent>) => void;
  keyUp?: (event: JQuery.KeyUpEvent) => void;
  mouseDown?: (x: number, y: number) => void;
  mouseMove?: (x: number, y: number) => void;
  mouseUp?: (x: number, y: number) => void;
  name: string;
  reset: () => void;
  setState?: (s: AnyObject) => void;
  update: (deltaSecs: number) => Any;
}

interface Arrows {
  ARROWS: BottomControlStates;
}
interface BottomControlStates {
  buttons: TempButtons[];
  down_visible: boolean;
  height: number;
  left_visible: boolean;
  right_visible: boolean;
  space_bar_visible: boolean;
  type: number;
  up_visible: boolean;
}

// Add new Button Types here
function getBottomControlStates(): Arrows {
  return {
    ARROWS: {
      buttons: [],
      down_visible: true,
      height: 80,
      left_visible: true,
      right_visible: true,
      space_bar_visible: true,
      type: 1,
      up_visible: true,
    },
  };
}

let bottomControls:
  | {
      buttons: TempButtons[];
      height: number;
      type: number;
    }
  | undefined;

// milliseconds
const buttonAnimationTime = 200;
const arrowM = 2;
const arrowJagPercent = 0.15;
const arrowWidthPercent = 0.4;
const arrowStraightPercent = 0.6;

function buildBottomControls(bC: BottomControlStates): void {
  const tempButtons = [];

  // Type: 1 - ARROWS
  if (bC.type === 1) {
    const vm = 5;
    const totalH = bC.height;
    // we don't paint the entire zone defined by this h, w pair
    // however, we DO hitTest for mouse/tap events
    // this way, we don't have tiny strips of dead zone between buttons
    const buttonH = totalH / 2;
    const buttonW = buttonH;

    const paintButton = (
      ctx: CTX,
      w: number,
      h: number,
      dir: Direction
    ): Any => {
      ctx.translate(vm, vm);
      paintArrowOnButton(ctx, w - 2 * vm, h - 2 * vm, dir);
      ctx.translate(-vm, -vm);
    };

    const centerX = w / 2 - buttonW / 2;
    const xPosition = centerX + buttonW;

    // Up Arrow
    if (bC.up_visible) {
      tempButtons.push(
        buildBottomControlButton({
          x: centerX,
          y: 0,
          w: buttonW,
          h: buttonH,
          outerMargin: vm,
          name: 'up',
          keyCode: 38,
          innerPaint: function (ctx: CTX, w: number, h: number) {
            paintButton(ctx, w, h, 'up');
          },
        })
      );
    }

    // Down Arrow
    if (bC.down_visible) {
      tempButtons.push(
        buildBottomControlButton({
          x: centerX,
          y: buttonH,
          w: buttonW,
          h: buttonH,
          outerMargin: vm,
          name: 'down',
          keyCode: 40,
          innerPaint: function (ctx: CTX, w: number, h: number) {
            paintButton(ctx, w, h, 'down');
          },
        })
      );
    }

    // Left Arrow
    if (bC.left_visible) {
      tempButtons.push(
        buildBottomControlButton({
          x: centerX - buttonW,
          y: 0.5 * buttonH,
          w: buttonW,
          h: buttonH,
          outerMargin: vm,
          name: 'left',
          keyCode: 37,
          innerPaint: function (ctx: CTX, w: number, h: number) {
            paintButton(ctx, w, h, 'left');
          },
        })
      );
    }

    // Right Arrow
    if (bC.right_visible) {
      tempButtons.push(
        buildBottomControlButton({
          x: xPosition,
          y: 0.5 * buttonH,
          w: buttonW,
          h: buttonH,
          outerMargin: vm,
          name: 'right',
          keyCode: 39,
          innerPaint: function (ctx: CTX, w: number, h: number) {
            paintButton(ctx, w, h, 'right');
          },
        })
      );
    }

    // Space Bar
    if (bC.space_bar_visible) {
      const spaceInnerM = 3;
      const spaceText = 'space';

      ctx.font = '16px Arial';
      const spaceDim = ctx.measureText(spaceText);
      const spaceW = spaceDim.width + spaceInnerM * 2;
      // based on font
      const spaceH = 16 + spaceInnerM * 2;

      tempButtons.push(
        buildBottomControlButton({
          x: xPosition + buttonW + 20,
          y: totalH / 2 - spaceH / 2,
          w: spaceW,
          h: spaceH,
          outerMargin: 0,
          name: 'space',
          keyCode: 32,
          innerPaint: function (ctx: CTX) {
            ctx.font = '16px Arial';
            ctx.fillStyle = 'black';
            ctx.fillText(spaceText, spaceInnerM, spaceH - spaceInnerM);
          },
        })
      );
    }
  }

  // All future button collections MUST have a type and height
  bottomControls = {
    buttons: tempButtons,
    height: bC.height,
    type: bC.type,
  };
}

interface TempButtons {
  animated: boolean;
  animatedTime: number;
  h: number;
  hitCheck: (hx: number, hy: number) => boolean;
  x: number;
  y: number;
  w: number;
  mouseDown?: (x: number, y: number) => void;
  name: string;
  onHit: () => void;
  outerMargin: number;
  paint: (ctx: CTX) => void;
}

interface BuildBottomControlButton {
  h: number;
  innerPaint: (ctx: CTX, w: number, h: number) => void;
  keyCode: number;
  name: string;
  outerMargin: number;
  w: number;
  x: number;
  y: number;
}

// x and y are relative to bottom panel only
function buildBottomControlButton({
  h,
  innerPaint,
  keyCode,
  name,
  outerMargin,
  w,
  x,
  y,
}: BuildBottomControlButton): TempButtons {
  const ret = {
    animated: false,
    animatedTime: buttonAnimationTime,
    x: x,
    y: y,
    w: w,
    h: h,
    outerMargin: outerMargin,
    paint: function (ctx: CTX) {
      ctx.save();

      roundRect(
        ctx,
        x + outerMargin,
        y + outerMargin,
        w - 2 * outerMargin,
        h - 2 * outerMargin,
        3,
        ret.animated ? 'gray' : 'lightgray'
      );

      if (innerPaint) {
        ctx.translate(x, y);
        innerPaint(ctx, w, h);
        ctx.translate(-x, -y);
      }

      ctx.restore();
    },
    name: name,
    hitCheck: function (hx: number, hy: number) {
      return hx >= x && hx <= x + w && hy >= y && hy <= y + h;
    },
    onHit: function () {
      if (game.keyDown) {
        game.keyDown({
          which: keyCode,
        });
        ret.animated = true;
        ret.animatedTime = buttonAnimationTime;
      }
    },
  };

  return ret;
}

interface BuiltButton {
  borderColor: string;
  buttonHit: () => void;
  fillColor: string;
  h: number;
  hitCheck: (hx: number, hy: number) => boolean;
  mouseDown: (x: number, y: number) => void;
  mouseMove: (x: number, y: number) => void;
  paint: (ctx: CTX) => void;
  text: string;
  textColor: string;
  w: number;
  x: number;
  y: number;
}

interface BuildButton {
  buttonHit: () => void;
  h: number;
  text: string;
  w: number;
  x: number;
  y: number;
}

// for building general buttons, not bottom controls
function buildButton({
  buttonHit,
  h,
  text,
  w,
  x,
  y,
}: BuildButton): BuiltButton {
  const ret = {
    x: x,
    y: y,
    w: w,
    h: h,
    text: text,
    buttonHit: buttonHit,
    textColor: 'black',
    fillColor: '#22b5e3',
    borderColor: '#22b5e3',

    paint: function (ctx: CTX) {
      ctx.save();

      roundRect(ctx, x, y, w, h, 10, this.fillColor, this.borderColor, 6);

      ctx.fillStyle = this.textColor;
      ctx.font = (2 * h) / 5 + 'px Arial';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(text, x + w / 2, y + h / 2);

      ctx.restore();
    },

    hitCheck: function (hx: number, hy: number) {
      return hx >= x && hx <= x + w && hy >= y && hy <= y + h;
    },

    mouseMove: function (x: number, y: number) {
      if (this.hitCheck(x, y)) {
        this.fillColor = '#84daf5';
        this.borderColor = '#84daf5';
      } else {
        this.fillColor = '#22b5e3';
        this.borderColor = '#22b5e3';
      }
    },

    mouseDown: function (x: number, y: number) {
      if (this.hitCheck(x, y)) this.buttonHit();
    },
  };

  return ret;
}

function addGame(g: Game): void {
  games[g.name] = g;
}

function hasGame(n: string): boolean {
  return n in games;
}

function getGame(n: string): Game {
  return games[n];
}

function paintTop(): void {
  ctx.save();
  ctx.fillStyle = 'black';
  ctx.fillRect(0, 0, w, h + topHeight);
  ctx.fillStyle = 'white';
  ctx.font = '18px arial';
  ctx.textBaseline = 'middle';
  const displayName = game.displayName || game.name;

  ctx.fillText(displayName, 5, 15);

  let x = 5 + ctx.measureText(displayName).width + 10;

  ctx.fillStyle = 'red';
  ctx.fillText(Math.floor(time) + '', x, 15);

  if (typeof game.getScore == 'function') {
    x = w * 0.55;
    ctx.fillStyle = 'white';
    ctx.fillText('Score:', x, 15);
    ctx.fillStyle = 'green';
    x += ctx.measureText('Score:').width + 10;
    ctx.fillText(Math.floor(game.getScore()) + '', x, 15);
  }

  ctx.strokeStyle = 'white';
  ctx.beginPath();
  ctx.moveTo(0, topHeight);
  ctx.lineTo(w, topHeight);
  ctx.closePath();
  ctx.stroke();

  ctx.lineWidth = 2;
  ctx.beginPath();
  ctx.moveTo(279, 7);
  ctx.lineTo(295, 23);
  ctx.moveTo(279, 23);
  ctx.lineTo(295, 7);
  ctx.closePath();
  ctx.stroke();
  ctx.restore();
}

function paintBottom(bottomControls: AnyObject): void {
  ctx.save();
  ctx.translate(0, topHeight + h);

  ctx.strokeStyle = 'white';
  ctx.lineWidth = 1;
  ctx.beginPath();
  ctx.moveTo(0, 0);
  ctx.lineTo(w, 0);
  ctx.closePath();
  ctx.stroke();

  ctx.fillStyle = 'black';
  ctx.fillRect(0, 0, w, bottomControls.height);

  for (let i = 0; i < bottomControls.buttons.length; i++) {
    const button = bottomControls.buttons[i];

    button.paint(ctx);
  }

  ctx.translate(0, -(topHeight + h));
  ctx.restore();
}

type Direction = 'up' | 'down' | 'left' | 'right';

function paintArrowOnButton(
  ctx: Any,
  w: number,
  h: number,
  direction: Direction
): void {
  ctx.save();

  w -= 2 * arrowM;
  h -= 2 * arrowM;
  ctx.translate(arrowM, arrowM);

  const jag = arrowJagPercent * w;
  const aW = arrowWidthPercent * w;
  const straightLength = arrowStraightPercent * h;

  ctx.strokeStyle = 'black';

  ctx.beginPath();

  if (direction === 'up') {
    ctx.moveTo(w / 2, 0);
    ctx.lineTo(w / 2 + aW / 2 + jag, h - straightLength);
    ctx.lineTo(w / 2 + aW / 2, h - straightLength);
    ctx.lineTo(w / 2 + aW / 2, h);
    ctx.lineTo(w / 2 - aW / 2, h);
    ctx.lineTo(w / 2 - aW / 2, h - straightLength);
    ctx.lineTo(w / 2 - (aW / 2 + jag), h - straightLength);
  } else if (direction === 'down') {
    ctx.moveTo(w / 2, h);
    ctx.lineTo(w / 2 + aW / 2 + jag, straightLength);
    ctx.lineTo(w / 2 + aW / 2, straightLength);
    ctx.lineTo(w / 2 + aW / 2, 0);
    ctx.lineTo(w / 2 - aW / 2, 0);
    ctx.lineTo(w / 2 - aW / 2, straightLength);
    ctx.lineTo(w / 2 - (aW / 2 + jag), straightLength);
  } else if (direction === 'right') {
    ctx.moveTo(w, h / 2);
    ctx.lineTo(straightLength, h / 2 + aW / 2 + jag);
    ctx.lineTo(straightLength, h / 2 + aW / 2);
    ctx.lineTo(0, h / 2 + aW / 2);
    ctx.lineTo(0, h / 2 - aW / 2);
    ctx.lineTo(straightLength, h / 2 - aW / 2);
    ctx.lineTo(straightLength, h / 2 - (aW / 2 + jag));
  } else if (direction == 'left') {
    ctx.moveTo(0, h / 2);
    ctx.lineTo(w - straightLength, h / 2 + aW / 2 + jag);
    ctx.lineTo(w - straightLength, h / 2 + aW / 2);
    ctx.lineTo(w, h / 2 + aW / 2);
    ctx.lineTo(w, h / 2 - aW / 2);
    ctx.lineTo(w - straightLength, h / 2 - aW / 2);
    ctx.lineTo(w - straightLength, h / 2 - (aW / 2 + jag));
  }

  ctx.closePath();
  ctx.stroke();

  ctx.translate(-arrowM, -arrowM);
  ctx.restore();
}

function paintLoss(): void {
  ctx.fillStyle = '#000000';
  ctx.globalAlpha = 0.5;
  ctx.fillRect(0, 0, w, h);

  ctx.fillStyle = '#FFFF01';
  ctx.font = '16px Arial';
  ctx.globalAlpha = 1;
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  ctx.fillText(lossMsg!, w / 2, 50);

  for (let i = 0; i < buttons.length; i++) {
    buttons[i].paint(ctx);
  }

  ctx.fillStyle = '#FFFFFF';

  if (tchHighs) {
    ctx.font = 'bold 14px Arial';
    ctx.textAlign = 'center';
    ctx.fillText('Class Highs', w / 4, 190);
    drawHighs(ctx, tchHighs, 50, 210);
  }

  if (orgHighs) {
    ctx.font = 'bold 14px Arial';
    ctx.textAlign = 'center';
    ctx.fillText('School Highs', (3 * w) / 4, 190);
    drawHighs(ctx, orgHighs, 200, 210);
  }
}

function paintPause(): void {
  ctx.font = '32px Arial';
  ctx.globalAlpha = 1;
  ctx.textAlign = 'center';

  ctx.fillStyle = '#FFFF01';
  ctx.fillText(
    Math.ceil(pauseTimer) + '',
    gameMaster.getDim().w / 2,
    gameMaster.getDim().h / 2
  );
}

function paintInstructions(): void {
  ctx.font = '32px Arial';
  ctx.globalAlpha = 1;
  ctx.textAlign = 'center';
  const ctrX = gameMaster.getDim().w / 2;

  ctx.fillStyle = '#000000';
  ctx.globalAlpha = 0.4;
  ctx.fillRect(0, 0, w, h);
  ctx.globalAlpha = 1;

  ctx.fillStyle = '#FFFF01';

  ctx.font = '18px Arial';

  const y = gameMaster.getDim().h / 4;

  for (let index = 0; index < instructions!.length; index++) {
    const curLn = instructions![index];

    ctx.fillText(curLn, ctrX, y + 20 * index);
  }
}

interface HighScores {
  name: string;
  score: number;
}

function drawHighs(
  ctx: CTX,
  highScrs: HighScores[],
  x: number,
  y: number
): void {
  ctx.font = '12px Arial';

  highScrs.forEach(function (curScore) {
    ctx.textAlign = 'right';
    ctx.fillText(`${curScore.score}`, x, y);

    ctx.textAlign = 'left';
    ctx.fillText(curScore.name, x + 5, y);
    y += 14;
  });
}

function draw(): void {
  paintTop();

  if (bottomControls) {
    paintBottom(bottomControls);
  }

  ctx.save();
  ctx.translate(0, topHeight);
  ctx.rect(0, 0, w, h);
  ctx.clip();
  game.draw(ctx);

  if (gameLost) {
    paintLoss();
  } else if (pauseTimer) {
    paintPause();
  } else if (instructions) {
    paintInstructions();
  }

  ctx.restore();
}

function update(delta: number): void {
  const deltaSecs = delta / 1000;

  time -= deltaSecs;

  // ranOutOfTime is bug patch for sporadic
  // extra 1 to 50 fires of update from MainLoop.js
  if (time <= 0 && !ranOutOfTime) {
    ranOutOfTime = true;
    stopGame(true);

    return;
  }

  if (bottomControls) {
    for (let i = 0; i < bottomControls.buttons.length; i++) {
      const button = bottomControls.buttons[i];

      if (button.animated) {
        button.animatedTime -= delta;

        if (button.animatedTime <= 0) {
          button.animated = false;
        }
      }
    }
  }

  if (pauseTimer) {
    pauseTimer -= deltaSecs;

    if (pauseTimer <= 0) {
      pauseTimer = undefined;
    } else {
      return;
    }
  } else if (instructions) {
    return;
  }

  game.update(deltaSecs);
}

function onMouseDown(event: JQuery.MouseDownEvent): void {
  const x = event.offsetX;
  let y = event.offsetY;

  mouseX = x;
  mouseY = y;

  if (x >= w - 30 && y <= 28) {
    stopGame(false);

    return;
  }

  // game events shouldn't be called if pauseTimer or instructions are showing
  if (pauseTimer) {
    return;
  }

  if (!gameLost) {
    if (instructions) {
      instructions = undefined;
    } else if (game.mouseDown && y < topHeight + h) {
      game.mouseDown(x, y - topHeight);
    } else if (useBottomControls()) {
      y = y - (topHeight + h);

      bottomControls?.buttons.some(button => {
        const hit = button.hitCheck(x, y);

        if (hit) {
          button.onHit();

          return true;
        }

        return false;
      });
    }
  }

  for (let i = 0; i < buttons.length; i++) {
    buttons[i].mouseDown(x, y - topHeight);
  }
}

function onMouseUp(event: JQuery.MouseUpEvent): void {
  if (pauseTimer || gameLost) return;

  const x = event.offsetX;
  const y = event.offsetY;

  if (game.mouseUp) {
    game.mouseUp(x, y - topHeight);
  }
}

const keyDown = (e: KeyboardEvent) => {
  convertToJQueryKeyDownEvent(e);
};

// Function to convert and trigger jQuery keydown event
function convertToJQueryKeyDownEvent(nativeEvent: KeyboardEvent) {
  // Create a new jQuery.Event object
  const jqEvent = jQuery.Event('keydown', {
    key: nativeEvent.key,
    keyCode: nativeEvent.keyCode,
    which: nativeEvent.which,
  });

  // Trigger the event on the desired element
  onKeyDown(jqEvent as JQuery.KeyDownEvent);
}

function onKeyDown(event: JQuery.KeyDownEvent): void {
  if (pauseTimer || gameLost) return;

  if (instructions) {
    instructions = undefined;
  } else if (game.keyDown) {
    game.keyDown(event);
  }
}

function onKeyUp(event: JQuery.KeyUpEvent): void {
  if (pauseTimer || gameLost) return;

  if (game.keyUp) {
    game.keyUp(event);
  }
}

function onMouseMove(event: JQuery.MouseMoveEvent): void {
  if (pauseTimer) return;

  const x = event.offsetX;
  const y = event.offsetY;

  mouseX = x;
  mouseY = y;

  for (let i = 0; i < buttons.length; i++) {
    buttons[i].mouseMove(x, y - topHeight);
  }

  if (game.mouseMove && !gameLost) {
    game.mouseMove(x, y - topHeight);
  }
}

// time expired or user quit early
function stopGame(save: boolean): void {
  MainLoop.stop();
  let saveData = save ? getSaveData() : null;

  if (instructions) saveData = null;

  if (saveData === null) game.reset();

  $('#gameCanvas').off('mousedown', onMouseDown);
  $('#gameCanvas').off('mouseup', onMouseUp);
  $(window).off('keydown', onKeyDown);
  $(window).off('keyup', onKeyUp);

  gameEnded(game.name, saveData);
  if (saveData) gamesPlayed.push(game.name);
  instructions = undefined;
  reset();
}

function useBottomControls(): boolean {
  return game.bottomControls && isTouchDevice();
}

function setGame(g: Game, t: number, s: string): void {
  studentAppModalState().setCurrentModal(GAME);

  game = g;
  time = t;

  canvas = $<HTMLCanvasElement>('#gameCanvas')[0];
  ctx = canvas.getContext('2d')!;

  if (useBottomControls()) {
    buildBottomControls(game.bottomControls);
  }

  // checks if the game has both been played before this session and has saved data
  // if so, countdown timer should show instead of instructions
  let localSave;

  for (let i = 0; i < gamesPlayed.length; i++) {
    if (gamesPlayed[i] == game.name) {
      localSave = true;
      gamesPlayed.splice(i, 1);
      break;
    }
  }

  if (!localSave) game.reset();

  if (s) {
    const parsed = JSON.parse(s) as Partial<Game>;

    if (game.setState) game.setState(parsed);
    else {
      for (const key in parsed) {
        if (Object.prototype.hasOwnProperty.call(parsed, key)) {
          const k = key as keyof Game;

          // @ts-expect-error
          game[k] = parsed[k];
        }
      }
    }

    pauseTimer = 3;
  } else if (localSave) {
    pauseTimer = 3;
  } else {
    createInstructions();
  }

  let canvasH = h + topHeight;

  if (useBottomControls()) {
    canvasH += game.bottomControls.height;
  }

  applyPixelRatioToCanvas(canvas, 300, canvasH);

  $('#gameCanvas').on('mousedown', onMouseDown);
  $('#gameCanvas').on('mouseup', onMouseUp);
  $('#gameCanvas').on('mousemove', onMouseMove);
  $(window).on('keydown', onKeyDown);
  $(window).on('keyup', onKeyUp);

  paintTop();
  game.draw(ctx);
  ranOutOfTime = false;
  MainLoop.setUpdate(update).setDraw(draw).start();
}

function createInstructions(): void {
  instructions = [];

  if (game.instructions) {
    instructions.push(...game.instructions);
    instructions.push('');
  }

  instructions.push('Click anywhere to begin.');
}

function getSaveData(): AnyObject | undefined {
  return game.getState ? game.getState() : undefined;
}

function reset(): void {
  bottomControls = undefined;
  gameLost = undefined;
  lossMsg = undefined;
  tchHighs = undefined;
  orgHighs = undefined;
  pauseTimer = undefined;
  buttons = [];
}

// Dear game designer: plz call this on game-ending event such as when player runs out of lives
// OR player wins ultimate victory.  Game clock is still ticking, but I need the score of the finished game
function gameFinished(score: number, msg: string): void {
  gameLost = true;
  lossMsg = msg;
  buttons.push(
    buildButton({
      x: 33,
      y: 100,
      w: 100,
      h: 40,
      text: 'Play Again',
      buttonHit: function () {
        gameLost = undefined;
        game.reset();
        reset();
      },
    })
  );

  buttons.push(
    buildButton({
      x: 166,
      y: 100,
      w: 100,
      h: 40,
      text: 'Instructions',
      buttonHit: function () {
        gameLost = undefined;
        createInstructions();
        game.reset();
        reset();
      },
    })
  );

  // if the mouse is within the bounds of the button when it's drawn but doesn't move, the button should still highlight
  for (let i = 0; i < buttons.length; i++) {
    buttons[i].mouseMove(mouseX, mouseY - topHeight);
  }

  // Use Math.floor to prevent non-integral values. Server casts inbound score to a Long.
  gameFinisher(game.name, Math.floor(score));
}

function setHighScores(tHi: HighScores[], oHi: HighScores[]): void {
  tchHighs = tHi;
  orgHighs = oHi;
}

interface Dimensions {
  w: number;
  h: number;
}

function getDim(): Dimensions {
  return { w: w, h: h };
}

function normalRandom(): number {
  let x1, x2, rad;

  do {
    x1 = 2 * Math.random() - 1;
    x2 = 2 * Math.random() - 1;
    rad = x1 * x1 + x2 * x2;
  } while (rad >= 1 || rad === 0);

  const c = Math.sqrt((-2 * Math.log(rad)) / rad);

  return x1 * c;
}

function getInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getRandomColor(): string {
  return (
    'rgb(' +
    getInt(0, 255) +
    ', ' +
    getInt(0, 255) +
    ', ' +
    getInt(0, 255) +
    ')'
  );
}

function roundRect(
  ctx: CTX,
  x: number,
  y: number,
  w: number,
  h: number,
  r: number,
  fillColor: string,
  lineColor?: string,
  thick?: number
): void {
  ctx.save();
  ctx.lineWidth = thick || 1;
  if (w < 2 * r) r = w / 2;
  if (h < 2 * r) r = h / 2;
  ctx.beginPath();
  ctx.moveTo(x + r, y);
  ctx.arcTo(x + w, y, x + w, y + h, r);
  ctx.arcTo(x + w, y + h, x, y + h, r);
  ctx.arcTo(x, y + h, x, y, r);
  ctx.arcTo(x, y, x + w, y, r);
  ctx.closePath();

  if (lineColor) {
    ctx.strokeStyle = lineColor;
    ctx.stroke();
  }

  if (fillColor) {
    ctx.fillStyle = fillColor;
    ctx.fill();
  }

  ctx.restore();
}

const gameMaster = {
  setGame: setGame,
  hasGame: hasGame,
  addGame: addGame,
  getGame: getGame,
  getSaveData: getSaveData,
  gameFinished: gameFinished,
  setHighScores: setHighScores,
  getDim: getDim,
  normalRandom: normalRandom,
  getInt,
  getRandomColor,
  getBottomControlStates,
  keyDown,
};

window.gameMaster = gameMaster;

export default gameMaster;

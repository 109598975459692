import { bannerState, problemState } from '../../stores';
import { getIsTest, setSnackbarMessage } from '../../stores/globalState';
import { GAME, studentAppModalState } from '../../stores/studentAppModalStore';
import { workState } from '../../stores/workStore';
import { Exam, ExamDatum } from '../../types';
import { gmmAlert } from '../../utils';
import { allowGames } from '../../utils/gmmUtils';
import { alerts } from '../alerts';
import {
  normalizeExamData,
  unlockAll,
  updateGameCredits,
  updateSwitchToSpiralReviewSettings,
} from '../responseHandlerShared';
import { postAssignmentChoice } from '../sendObject';

import { ExamMessages, PingSuccess } from './types';

export const updateGameCreditsAndTopScores = (data: PingSuccess): void => {
  if (data.gameCredits) {
    updateGameCredits(
      data.gameCredits,
      data.pointsTowardGameCredit,
      data.pointsForGameCredit
    );
  }

  if (data.topScores) {
    studentAppModalState().setTopScores(data.topScores.scoreRows);
  }

  if (data.clasHighestToday) {
    bannerState().setClassHighestToday(data.clasHighestToday);
  }

  return;
};

export const updateWallet = (amount: number): void => {
  updateGameCredits(amount);

  if (allowGames() && !getIsTest()) {
    gmmAlert(alerts.teacherCredits);
  }
};

export const updateReplacements = (
  replacementsPerDay: number | undefined
): void => {
  if (replacementsPerDay !== undefined) {
    bannerState().setReplacementsPerDay(replacementsPerDay);
  }
};

export const updateAllowReplacementsWhenTeacherOnline = (
  allowReplacementsWhenTeacherOnline: boolean
): void => {
  bannerState().setAllowReplacementsWhenTeacherOnline(
    allowReplacementsWhenTeacherOnline
  );
};

export const clasMessages = (data: PingSuccess): void => {
  data.clasMsgs.forEach(msg => {
    studentAppModalState().addStackableMessageFromTeacher(msg.msg);
  });
};

export const unlockExamProblems = (): void => {
  problemState().setAutoUnlockExamsProblems(true);
  unlockAll();
};

export const snackbarNotifications = (data: PingSuccess): void => {
  data.notifications?.forEach(notification => {
    switch (notification.type) {
      case 'allowExamsOnMobile':
        bannerState().setAllowExamsOnMobile(
          notification.json.allowExamsOnMobile
        );
        break;

      case 'allowWorkOnAnyProblems':
        setSnackbarMessage({
          autoHideDuration: null,
          message:
            'Your teacher updated a class setting that changed the problems you can work on.',
        });
        problemState().setDollarsOnly(
          !notification.json.allowWorkOnAnyProblems
        );
        break;

      case 'workChangedAlert':
        if (studentAppModalState().currentModal !== GAME && !getIsTest()) {
          gmmAlert(alerts.assignmentChanged);
        }

        break;

      case 'requireWorkOnDefaultAssignment':
        {
          const availableWork = studentAppModalState().availableWork;
          const currentWork = workState().currentWork;
          const defaultAssignment = availableWork.find(
            work => work.defaultAssignment
          );
          const isDefaultAssignmentCompleted =
            defaultAssignment &&
            defaultAssignment.points! >= defaultAssignment.required!;

          updateSwitchToSpiralReviewSettings({
            requireStudentsToFinishDefaultAssignment:
              notification.json.requireWorkOnDefaultAssignment,
            isClasDefaultWorkSpiralReview: workState()
              .isClasDefaultWorkSpiralReview,
          });

          if (!isDefaultAssignmentCompleted) {
            postAssignmentChoice(
              defaultAssignment!.workId!,
              defaultAssignment!.workType!
            );

            if (
              workState().requireStudentsToFinishDefaultAssignment &&
              currentWork.workId !== defaultAssignment?.workId &&
              studentAppModalState().currentModal !== GAME &&
              !getIsTest()
            )
              gmmAlert(alerts.assignmentChanged);
          }
        }

        break;
    }
  });
};

export const examMessages = (data: PingSuccess): void => {
  data.examMessages?.forEach(message => {
    switch (message.type) {
      case 'EXAM_ADDED':
      case 'EXAM_ENABLED':
        examAddedToClass(message);
        break;

      case 'EXAM_REMOVED':
        examRemovedFromClass(message);
        break;

      case 'EXAM_PAUSED':
        pauseExam(message);
        break;

      case 'EXAM_DISABLED':
        examDisabled(message);
        break;

      case 'EXAM_TURNED_IN':
        examTurnedIn(message);
        break;
    }
  });
};

const examRemovedFromClass = function (examMessage: ExamMessages): void {
  studentAppModalState().removeExams(normalizeExamData([examMessage]));

  isStudentInExam(examMessage) && gmmAlert(alerts.examRemoved);
};

const pauseExam = function (examMessage: ExamMessages): void {
  isStudentInExam(examMessage) && gmmAlert(alerts.pausedExam);
};

const examTurnedIn = function (examMessage: ExamMessages): void {
  isStudentInExam(examMessage) && gmmAlert(alerts.turnedInExam);
};

const examAddedToClass = function (examMessage: ExamMessages): void {
  const exam = examMessage as ExamDatum;

  studentAppModalState().addExams(normalizeExamData([exam]));
};

const examDisabled = function (examMessage: ExamMessages): void {
  const exam = examMessage as ExamDatum;

  studentAppModalState().removeExams(normalizeExamData([exam]));
};

const isStudentInExam = (examMessage: ExamMessages): boolean => {
  const work = workState().currentWork;

  if (work.type !== 'EXAM') return false;

  const exam = work as Exam;
  const studentInTestId = exam.sitId;

  return studentInTestId === examMessage.sitId;
};



import { DisplayHighs } from '../../legacy/game';
import { sendObject } from './handler';

export type GameFinished = {
  type: 'gameFinished';
  game: string;
  score: number;
};

type Options = {
  game: string;
  score: number;
};

// as in, won the game, or lost all lives
// time is still ticking, but for a fresh start
export const postGameFinished = async (
  { game, score }: Options,
  onSuccess: (data: DisplayHighs) => void
): Promise<void> =>
  sendObject<DisplayHighs>(
    { type: 'gameFinished', game, score },
    {
      onSuccess,
    }
  );

import clsx from 'clsx';
import { times } from 'lodash';
import { FC } from 'react';

import { getColorData, colorsArray } from '../../../utils';

import { BoxIcon } from './boxIcon';

export const cornerClasses = [
  'triangle-topleft',
  'triangle-topright',
  'triangle-bottomleft',
  'triangle-bottomright',
];

interface Props {
  isPenaltyOrWrong: boolean;
  className?: string;
  isBack?: boolean;
  isDollar: boolean;
  level: number;
  skips?: number | null;
  iconSize?: number;
}

export const BoxFace: FC<Props> = ({
  isPenaltyOrWrong,
  className,
  isBack,
  isDollar,
  level,
  skips,
  iconSize,
}) => {
  const { iconColor, isStar } = getColorData(colorsArray[level]);

  const corners = times(skips ?? 0, index => (
    <div key={index} className={cornerClasses[index]} />
  ));
  const transform = isBack ? 'rotateY(180deg)' : 'none';

  return (
    <div
      className={clsx(className, {
        [`level${level}`]: level,
      })}
      style={{
        transform,
      }}
    >
      <BoxIcon
        iconColor={iconColor}
        size={iconSize}
        isDollar={isDollar}
        isStar={isStar}
        isPenaltyOrWrong={isPenaltyOrWrong}
      />
      {corners}
    </div>
  );
};

import * as qs from 'querystring';

import { AxiosError } from 'axios';
import { isObject } from 'lodash';

import { reboot } from '../utils/gmmUtils';

import { alerts } from './alerts';

// Student Settings, must be converted back to string bool before going up to server
// This is handled in the sendObject for their particular types.
export const boolToString = [
  'effects',
  'forceTouchKeyboard',
  'nativeKeyboard',
  'showTopScores',
  'showClock',
];

const submitStringBools = ['deckedPen', 'inkedPen', 'uF', 'inv', 'blanked'];

export const stringToBool = [
  'badCredentials',
  'bg',
  'bgclas',
  'clasOff',
  'noClas',
  'tO',
  ...boolToString,
  ...submitStringBools,
];

export function convertStringToBool<T extends Record<string, any>>(data: T): T {
  Object.keys(data).forEach(key => {
    // We never want to touch the `p` because it's parsed by ProblemJS
    if (key === 'p') return;

    const value = data[key];

    if (value === null || value === undefined) return;

    if (isObject(value)) {
      convertStringToBool(value);
    } else if (value === 't' || value === 'f') {
      (data as Record<string, any>)[key] = value === 't';
    }
  });

  return data;
}

export const handleSessionClosed = (): void => {
  reboot({ msg: 'Session closed -- did you log in somewhere else?' });

  return;
};

export const handleFail = (msg: string): void => {
  reboot({ msg });

  return;
};

export const processAxiosFailure = (
  err: AxiosError
): ParsedAxiosError | undefined => {
  const parsed = parsedAxiosError(err);

  if (parsed.status === 401) {
    reboot(alerts.sessionExpired);

    return;
  }

  consoleAxiosError(err);

  return parsed;
};

export const getErrorStatus = (err: AxiosError): ParsedAxiosError => {
  const { status, error } = parsedAxiosError(err);

  switch (status) {
    case 400:
      return { status, error: `Bad Request: ${error}` };
    case 401:
      return { status, error: `Unauthorized: ${error}` };
    case 404:
      return { status, error: `Not Found: ${error}` };
    case 500:
      return { status, error: `Internal Server Error: ${error}` };
    default:
      return { status, error };
  }
};

export const isAxiosError = <T = unknown>(
  error: unknown
): error is AxiosError<T> =>
  error instanceof Error && !!(error as AxiosError).isAxiosError;

export interface ParsedAxiosError {
  status: number;
  error: string;
}

export const parsedAxiosError = (error: AxiosError): ParsedAxiosError => {
  return {
    status: error.response?.status ?? 0,
    error: error.response?.data ?? '',
  };
};

export const is404 = (error: unknown): boolean =>
  isAxiosError(error) && error.response?.status === 404;

export const toBody = (args: any): string => {
  return qs.stringify(args);
};

export const consoleAxiosError = (error: AxiosError): void => {
  const parsed = parsedAxiosError(error);

  console.error(`StatusCode: ${parsed.status} -- Error: ${parsed.error}`);
};

import { OrderOption } from '../../types';
import { sendObject } from './handler';

export type OrderBy = {
  type: 'orderBy';
  orderBy: OrderOption;
};

export const postOrderBy = async (orderBy: OrderOption): Promise<void> =>
  sendObject({ type: 'orderBy', orderBy });

import { isBoolean } from 'lodash';

import { ProblemData } from '@gmm/problem';

import { postTchrRequestedProb, postOverride, postHandInExam } from '..';
import {
  ReceiveProblem,
  receiveProblem,
  unlockAll,
} from '../../api/responseHandlerShared';
import {
  bannerState,
  clearAllPenalties,
  problemState,
  setProblems,
} from '../../stores';
import { getGmm, getIsTest } from '../../stores/globalState';
import { studentAppModalState } from '../../stores/studentAppModalStore';
import { workState } from '../../stores/workStore';
import { Any, Exam, ID } from '../../types';
import { gmmAlert, allExamProblemsAreLocked } from '../../utils';
import { logHistory, logOff, reboot } from '../../utils/gmmUtils';
import { alerts } from '../alerts';

import { PingSuccess } from './types';

interface ExamExtend {
  autoUnlockExamsProblems?: boolean;
  problems?: 'empty';
}

export interface MessageTypes {
  accountDeleted: boolean;
  bg: boolean;
  forcelogoff: boolean;
  getHelp: boolean;
  hand: boolean;
  id: ID;
  msg: string;
  override: boolean;
  p: ProblemData;
  permitReadAloud: boolean;
  reason: string;
  replacementsPerDay: number | undefined;
  sender: string;
  sendErrorToServer: boolean;
  sitId: number;
  tchrmsg: string;
  tchrRequestsCurrent: boolean;
  test: ExamExtend | Any;
  testId: number;
  testRequestResponse: boolean;
  testRequestUnlockResponse: boolean;
  type:
    | 'clearPenalties'
    | 'denyunlock'
    | 'limitMultipleChoice'
    | 'targetedOverride'
    | 'teacherTurnedInExam'
    | 'teacherPausedExam'
    | 'unlock';
}

export const teacherToStudentMessages = (data: PingSuccess): void => {
  data.m.forEach((msg: MessageTypes) => {
    if (msg.testRequestResponse) {
      studentAppModalState().setExamLoading(false);
    }

    if (msg.testRequestUnlockResponse) {
      if (getIsTest() && msg.msg) {
        gmmAlert({
          msg: msg.msg,
          top: 'Message',
        });
      }
    }

    if (msg.accountDeleted) {
      reboot({ msg: 'You have been removed from this class.' });

      return;
    }

    if (msg.forcelogoff) {
      logOff();

      gmmAlert(alerts.loggedOff(msg.reason));
    }

    if (isBoolean(msg.bg)) {
      bannerState().setBlockGames(msg.bg);
    }

    if (msg.type === 'clearPenalties') {
      clearAllPenalties();
    }

    if (!getIsTest()) {
      if (isBoolean(msg.hand)) bannerState().setIsHandRaised(msg.hand);

      if (msg.tchrRequestsCurrent)
        postTchrRequestedProb(problemState().selectedID);

      if (msg.override) postOverride(problemState().selectedID);

      if (msg.msg) {
        if (!msg.sender) {
          gmmAlert({
            msg: msg.msg,
            top: 'Message',
            // So that "Your teacher has been notified..." pops when
            // later message "Your teacher has denied..." arrives.
            removeWhenAnotherDialogShows: true,
          });
        } else {
          studentAppModalState().addStackableMessageFromTeacher(msg.msg);
        }
      }

      if (msg.test) {
        const exam = msg.test;

        studentAppModalState().setExamLoading(false);

        if (exam.problems === 'empty') {
          gmmAlert(alerts.emptyExam);
        } else {
          studentAppModalState().closeAll();
          getGmm()?.setToBlank();
          workState().setCurrentWork({
            type: 'EXAM',
            name: exam.testName,
            sitId: msg.sitId,
            testId: msg.testId,
          } as Exam);

          setProblems(exam, true);
        }
      }
    }

    if (msg.type) {
      renderTeacherResponses(msg);
    }

    if (getIsTest() && msg.msg && msg.tchrmsg) {
      gmmAlert({
        msg: msg.msg,
        top: 'Message',
      });
    }
  });
};

function renderTeacherResponses(msg: MessageTypes): void {
  switch (msg.type) {
    case 'unlock': {
      unlockAll();
      break;
    }

    case 'denyunlock': {
      const alert = alerts.requestDenied;

      if (allExamProblemsAreLocked()) {
        alert.reload = true;
        alert.reloadIsInternal = true;
      }

      gmmAlert(alert);
      break;
    }

    case 'targetedOverride': {
      const targetedProblem = problemState().problems[`${msg.id}`];

      if (!targetedProblem) {
        logHistory(
          `Ignoring targeted override in renderTeacherResponses, as Restore ${msg.id} is no longer present in colored squares.`
        );

        return;
      }

      const problem: ReceiveProblem = {
        problem: msg.p,
        id: targetedProblem.id,
        performingTargetedOverride: true,
      };

      // If there is a pending replacement problem, the student is currently
      // viewing the answer to a problem on the 'Show Answer' dialog.
      // The targeted override should be delayed until the student closes dialog.
      // Also, the inbound targeted override should become the replacement problem,
      // since the inbound problem is now the official state for the Restore
      // on the server.
      const pendingReplacementProblem = studentAppModalState()
        .replacementProblem;

      // == because one id is a string and the other is a number
      if (
        pendingReplacementProblem &&
        pendingReplacementProblem.id == targetedProblem.id
      ) {
        studentAppModalState().setReplacementProblem(problem);

        return;
      }

      problemState().resetSquare(targetedProblem.id);
      receiveProblem(problem);

      break;
    }

    case 'teacherTurnedInExam': {
      const work = workState().currentWork;

      if (work.type !== 'EXAM') return;

      const exam = work as Exam;

      postHandInExam(exam.sitId);
      gmmAlert(alerts.turnedInExam);
      break;
    }

    case 'teacherPausedExam': {
      gmmAlert(alerts.pausedExam);
      break;
    }

    case 'limitMultipleChoice': {
      if (!getIsTest()) {
        gmmAlert(alerts.settingsChanged);
      }

      break;
    }
  }
}

import {
  getLastLoginTry,
  setLastLoginTry,
} from '../../stores/globalState';

import { LoginRequest } from './types';

const LOGIN_TRY_BUFFER = 500;

const { STUDENT_GIT_SHA } = process.env;

export const jsNames = `student-${STUDENT_GIT_SHA}.js problem-${STUDENT_GIT_SHA}.js`;

export const markLastLoginTry = (): void => {
  const now = new Date().getTime();
  const lastLoginTry = getLastLoginTry();

  if (lastLoginTry && lastLoginTry > now - LOGIN_TRY_BUFFER) {
    return;
  }

  setLastLoginTry(now);
};

export const getParamsFromUrl = (args: LoginRequest): LoginRequest => {
  const urlParams = new URLSearchParams(window.location.search);

  if (urlParams.has('classId')) {
    const classId = urlParams.get('classId') || undefined;

    args.classId = classId;
  }

  return {
    ...args,
    username: args.username || '',
    password: args.password || '',
    jsFiles: jsNames,
  };
};

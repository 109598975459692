import { FC } from 'react';

import { BoxIconType, getBoxIcon } from './boxIcons';

interface Props {
  iconColor: string;
  isDollar: boolean;
  isStar: boolean;
  isPenaltyOrWrong: boolean;
  size?: number;
}

export const BoxIcon: FC<Props> = ({ ...props }) => {
  if (!props.isDollar && !props.isStar && !props.isPenaltyOrWrong) return null;

  const icon = props.isPenaltyOrWrong
    ? (`exclamation-${props.iconColor}` as BoxIconType)
    : props.isDollar
    ? (`dollar-${props.iconColor}` as BoxIconType)
    : (`star-${props.iconColor}` as BoxIconType);

  return getBoxIcon(icon, props.size);
};

import { AnimationControls, useAnimation } from 'framer-motion';
import { ControlsAnimationDefinition } from 'framer-motion/types/animation/types';
import { useEffect, useState } from 'react';

type Sequence = Array<string | ControlsAnimationDefinition>;

interface Config {
  loop?: boolean;
  onComplete?: () => void;
  sequence: Sequence;
}

export const useSequenceAnimation = ({
  loop = false,
  onComplete,
  sequence: sequenceList,
}: Config): {
  controls: AnimationControls;
  isAnimating: boolean;
  startAnimation: () => void;
  stopAnimation: () => void;
} => {
  const controls = useAnimation();
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const sequence = async (): Promise<void> => {
      for (const step of sequenceList) {
        await controls.start(step);
      }

      onComplete?.();

      if (loop) {
        sequence();
      } else {
        setIsAnimating(false);
      }
    };

    if (isAnimating) sequence();
  }, [controls, isAnimating, loop, sequenceList]);

  return {
    controls,
    isAnimating,
    startAnimation: () => setIsAnimating(true),
    stopAnimation: () => setIsAnimating(false),
  };
};

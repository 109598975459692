import { ExamProblem, NormalProblem } from '../stores';

import { ProblemObject } from './types';

type OneToNine = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
type Digit = 0 | OneToNine;

type Year = `${19 | 20}${Digit}${Digit}`;
type Month = `${`${OneToNine}` | `1${0 | 1 | 2}`}`;
type Day = `${`${OneToNine}` | `${1 | 2}${Digit}` | `${3}${0 | 1}`}`;

export type DateString = `${Month}/${Day}/${Year}`;

export type OrderOption =
  | 'Date Learned'
  | '$ First'
  | 'Skill Level'
  | 'Date of Attempt';

export const DEFAULT_ORDER_BY: OrderOption = 'Date Learned';

export type PreStoreExamProblem = {
  id: string;
  locked: boolean;
  tn: number;
  unseen: boolean;
  notValid: boolean;
  ready: boolean;
};

// Since ids are not sent in the payload, only as a key
type ToUnidentified<T> = T extends ProblemObject
  ? Omit<T, 'id'>
  : ProblemObject;

export type ExamProblems = Record<string, ExamProblem>;
export type NormalProblems = Record<string, NormalProblem>;

export type ProblemSet =
  | Record<string, ToUnidentified<PreStoreExamProblem>>
  | Record<string, ToUnidentified<NormalProblem>>;
export type Problems =
  | Record<string, ExamProblem>
  | Record<string, NormalProblem>;

import { Variants } from 'framer-motion';

const boxFaceClassName = 'boxShine';

const smallVariants = {
  initial: {
    '--translate-x': '-20px',
    rotateY: 0,
    scale: 1,
    zIndex: 1,
  },
  grow: {
    scale: 1.9,
    zIndex: 2,
    transition: {
      duration: 0.3,
      type: 'spring',
    },
  },
  flip: {
    rotateY: [null, 180] as [null, 180],
    zIndex: 2,
    transition: {
      duration: 0.5,
      ease: 'easeInOut',
      type: 'spring',
    },
  },
  scaleDown: {
    '--translate-x': '36px',
    scale: 1,
    transition: {
      duration: 0.8,
      type: 'spring',
      scale: {
        delay: 0.4,
      },
    },
    transitionEnd: {
      '--translate-x': '-20px',
      rotateY: 0,
      zIndex: 1,
    },
  },
} as Variants;

const modalVariants = {
  initial: {
    '--translate-x': '-34px',
    rotateY: 0,
    scale: 1,
    x: -300,
    skewX: '50deg',
  },
  slideIn: {
    x: '0px',
    skewX: '0deg',
    transition: {
      delay: 0.2,
      ease: 'easeIn',
      duration: 0.6,
      type: 'spring',
      bounce: 0.35,
    },
  },
  grow: {
    scale: 1.6,
    transition: {
      duration: 0.6,
      ease: 'easeIn',
      type: 'spring',
      bounce: 0.35,
      delay: 0.4,
    },
  },
  flip: {
    scale: 1.6,
    rotateY: [null, 160] as [null, 160],
    transition: {
      duration: 0.6,
      delay: 0.4,
      ease: [0.6, 0.1, 0.4, 0.8],
      type: 'spring',
    },
  },
  shimmer: {
    '--translate-x': '140px',
    scale: 1.6,
    rotateY: [null, 220] as [null, 220],
    transition: {
      duration: 0.5,
      ease: 'easeOut',
      type: 'spring',
      repeat: 1,
      repeatType: 'mirror',
      repeatDelay: 0.2,
    },
  },
  pause: {
    scale: 1.6,
    rotateY: [null, 180] as [null, 180],
    transition: { duration: 0.3 },
  },
  scaleDown: {
    scale: 0.8,
    transition: {
      bounce: 0.35,
      duration: 1,
      ease: 'easeIn',
      type: 'spring',
      scale: {
        delay: 0.4,
      },
    },
  },
  slideOut: {
    skewX: ['-15deg', '0deg'],
    x: 300,
    scale: 1,
    transition: {
      delay: 0.2,
      ease: 'easeIn',
      duration: 0.6,
      type: 'spring',
    },
    transitionEnd: {
      '--translate-x': '-30px',
      rotateY: 0,
      zIndex: 1,
      x: -300,
      skewX: '50deg',
    },
  },
} as Variants;

export const flippingBox = {
  small: {
    boxFaceClassName,
    sequence: ['grow', 'flip', 'scaleDown'],
    variants: smallVariants,
  },
  modal: {
    boxFaceClassName,
    sequence: [
      'slideIn',
      'grow',
      'flip',
      'shimmer',
      'pause',
      'scaleDown',
      'slideOut',
    ],
    variants: modalVariants,
  },
};

import { unstable_batchedUpdates } from 'react-dom';

import {
  AttemptData,
  ProblemData,
  Score,
  decodeProblemData,
} from '@gmm/problem';

import {
  convertStringToBool,
  handleFail,
  handleSessionClosed,
  alerts,
  sendErrorToServer,
} from '..';
import { getType } from '../../hooks';
import { showSmileys } from '../../legacy';
import { updateTimeStuckDisplay } from '../../legacy/activityMonitor';
import {
  ExamProblem,
  NormalProblem,
  bannerState,
  examBoxClick,
  problemState,
  setProblem,
} from '../../stores';
import { getIsTest, setTimeStuck } from '../../stores/globalState';
import { problemJsonMap } from '../../stores/problemJsonMap';
import { studentAppModalState } from '../../stores/studentAppModalStore';
import { newWork, workState } from '../../stores/workStore';
import {
  Assignment,
  NewWork,
  ProblemObjects,
  Proficiency,
  Work,
} from '../../types';
import { gmmAlert } from '../../utils';
import {
  handleSevereError,
  logHistory,
  logRestoreIds,
} from '../../utils/gmmUtils';
import {
  processAvailableWork,
  updateAllTime,
  updateGameCredits,
} from '../responseHandlerShared';

interface AttemptGetterStatus {
  problemJSON: ProblemData;
  agId: number;
  newStatus: 'c' | 'wr' | 'ready' | 'invalid';
}

export interface AttemptSubmitted extends AttemptData {
  availableWork?: Assignment[];
  guidFail?: string;
  sessionClosed?: boolean;

  // happens when submission completes assignment, server sends new Work
  problems?: ProblemObjects;
  /** Restore id used to auto select next square */
  selectedRestoreId?: number;

  // Fields for a Work partial or complete can be sent among
  // other fields at the root level of AttemptSubmitted either
  // to convey progress on current Work or details on new Work
  // type: WorkType;
  // workType?: WorkType;

  name?: string;
  date?: string;
  workId?: number | undefined;
  // workType: server sometimes sends type, sometimes sends workType
  type?: string;
  available?: boolean;

  sitId?: number;
  ticId?: number;

  // this pair is for assigned skills on an assignment
  // (May also do double duty for Corrections 'totals'??)
  points?: number;
  required?: number;

  // this pair is for SR skills on an assignment
  srPoints?: number;
  srRequired?: number;

  // this pair is for for the original exam mistakes on Corrections
  errorsFixed?: number;
  errorsRequired?: number;

  // this pair is for the extra practice on Corrections
  practicePoints?: number;
  practiceRequired?: number;

  lastAttempt?: number;
  lastFive?: Score;
  lastTen?: Score;
  lastThree?: Score;
  /** Color */
  lvl?: string;
  myAllTime?: Score;

  /** Penalties */
  pen?: number;

  /** IDs for problem types that should have dollar signs */
  dollarRestoreIds?: number[];
  incScore?: boolean;
  clasHighestToday?: number;
  allTimeEligibleOnly?: string;
  /** Score (points earned today) */
  s?: number;
  rawScore?: number;

  /** Points Toward Dollar */
  pointsTowardGameCredit?: number;
  /** Points Needed for Credit */
  pointsForGameCredit?: number;
  /** Game credit */
  gameCredits?: number;
}

/**
 * Called by problemJS as part of student submission.
 * First, problemJS applies some logic, then sends along
 * the response to student app via this function.
 */
export function attemptSubmitted(
  response: AttemptSubmitted,
  agId: number,
  autoSubmittedDuringTest = false,
  blank = false
): void | undefined | (() => void) {
  logHistory('begin student client attemptSubmitted function');

  if (response.sessionClosed) return handleSessionClosed();

  if (response.guidFail) return handleFail(response.guidFail);

  if (response.submitFail || response.submitFail2) {
    const msgToStudent = response.submitFail || response.submitFail2;

    gmmAlert(alerts.submitFail(msgToStudent!));

    const signal = response.submitFail ? 'submitFail' : 'submitFail2';
    const log = `WF sent student submit fail signal ${signal}`;

    console.error(log);
    sendErrorToServer(log);
  }

  if (response.submitFail2) {
    gmmAlert(alerts.submitFail(response.submitFail2));

    return;
  }

  if (response.severeError) return handleSevereError(response.severeError);

  const data = convertStringToBool(response);

  let returnFunctionForNextExamProblem: (() => void) | undefined = undefined;

  unstable_batchedUpdates(() => {
    let penaltyMessage;
    let penaltyHeader;

    if (!getIsTest()) {
      if (data.clasHighestToday) {
        bannerState().setClassHighestToday(data.clasHighestToday);
      }

      if (data.incScore) {
        bannerState().incrementPointsThisWeek();
      }

      if (data.allTimeEligibleOnly) {
        updateAllTime(data.allTimeEligibleOnly);
      }

      if (typeof data.gameCredits === 'number') {
        updateGameCredits(
          data.gameCredits,
          data.pointsTowardGameCredit,
          data.pointsForGameCredit
        );
      }

      if (data.s || data.s === 0) {
        bannerState().setPointsToday(data.s);
      }

      const invalid = data.inv && !data.blanked;
      const usedFreebie = data.uF;
      const totallyCorrect = data.tC;

      // Determine if smileys should be shown
      if (!invalid && !usedFreebie && totallyCorrect && bannerState().effects)
        showSmileys();

      // Change Work (submission finished current work)
      const workId = data.workId;
      const workType = data.type;

      if (
        (workId && workId !== workState().currentWork.workId) ||
        (workType && workType !== getType(workState().currentWork))
      ) {
        // store Work that was just finished to be used in finishedWorkDialog modal
        studentAppModalState().setFinishedWork({
          finishedWork: workState().currentWork,
        });

        // server minimally must send 'type' and 'name' of new work (for SPIRAL_REVIEW)
        // but may also send 'points', 'required', 'workId' (for ASSIGNMENT)
        // or errorsFixed, errorsRequired, practicePoints and practiceRequired (CORRECTIONS)
        newWork(data as NewWork);

        processAvailableWork(data.availableWork);

        return;
      } else {
        // updated progress on current work
        workState().updateCurrentWork(data as Work);
      }

      const normalProblem = problemState().problems[
        `${data.id}`
      ] as NormalProblem;

      if (!normalProblem) {
        logRestoreIds(
          `attemptSubmitted can't find Restore. Target id: ${data.id}.`
        );
      }

      const { id } = normalProblem;

      if (!invalid && !usedFreebie) {
        const penalties = data.pen || 0;
        const priorPenalties = problemState().getPenalties(id);

        if (penalties !== priorPenalties) {
          problemState().setPenalty(id, penalties);
          const penaltiesWord = penalties > 1 ? 'penalties' : 'penalty';
          const problemsWord = penalties > 1 ? 'problems' : 'problem';

          if (penalties > priorPenalties) {
            const msg = `You have ${penalties} ${penaltiesWord} on this problem. You cannot get a point for this until you work out ${penalties} extra ${problemsWord} correctly on your first try.`;

            gmmAlert({
              msg,
              style: 'red',
              top: 'Penalty',
            });
          } else {
            if (penalties > 0) {
              penaltyMessage = `Good: you got rid of one penalty. You have ${penalties} ${penaltiesWord} on that problem. You cannot get a point on it until you work out ${penalties} extra ${problemsWord} correct on your first try.`;
              penaltyHeader = 'Removed 1 Penalty';
            } else {
              penaltyMessage =
                'Good: you got rid of your last penalty on that problem.';
              penaltyHeader = 'Penalties Are Gone';
            }
          }
        }

        if (data.dollarRestoreIds) {
          problemState().setDollars(
            data.dollarRestoreIds.map((dollar: number) => `${dollar}`)
          );
        }

        if (data.lastThree || data.lastFive) {
          if (normalProblem) {
            problemState().updateProblem(id, {
              lastThree: data.lastThree,
              lastFive: data.lastFive,
              lastTen: data.lastTen,
              myAllTime: data.myAllTime,
              rawScore: data.rawScore,
              lastAttempt: data.lastAttempt,
            });
          }
        }

        if (totallyCorrect) {
          setTimeStuck(0);
          updateTimeStuckDisplay();

          if (normalProblem) {
            problemState().updateProblem(id, {
              status: 'right',
              skips: 0,
              daysSince: 0,
              lastCorrectDate: 'Today',
              // Totally correct attempt results in a 100% certainty of square not showing an unfixed exam problem
              isUnfixedExamCorrection: false,
            });

            if (data.lvl) {
              problemState().updateProblem(id, {
                lvl: data.lvl as Proficiency,
              });
            }
          }

          if (data.probGenerationFail) {
            gmmAlert(alerts.snafu(String(data.probFailType) || 'type unknown'));
          } else {
            decodeProblemData(data.p);

            if (normalProblem && normalProblem.type !== 'EXAM_CORRECTIONS') {
              if (data.p) {
                problemJsonMap.set(id, data.p);
                logHistory(
                  `totallyCorrect attemptSubmitted, so new json for Restore ${id} including new md5 ${data.p.md5}`
                );
              }
            }

            setProblem(data.selectedRestoreId || data.id!);
          }
        }

        // updates json record of attempt getter status for multi stage problems
        if (
          normalProblem &&
          (!totallyCorrect || normalProblem.type === 'EXAM_CORRECTIONS')
        ) {
          const problemJSON = problemJsonMap.get(id);

          // Problemjs has similar logic, but it's not the same.
          // There, we update the temporary state held by problemjs.
          // Here, we update the json store of the problem -- which
          // continues to exist as the student switches through problems.
          if (data.aC) {
            if (problemJSON) {
              setAttemptGetterStatus({ problemJSON, agId, newStatus: 'c' });
            }
          } else {
            if (problemJSON) {
              setAttemptGetterStatus({ problemJSON, agId, newStatus: 'wr' });
            }

            problemState().updateProblem(id, {
              hasBeenTried: true,
              firstTry: false,
              status: 'wrong',
            });
            if (data.lvl)
              problemState().updateProblem(id, {
                lvl: data.lvl as Proficiency,
              });
          }
        }
      }
      // isTest
    } else {
      const selectedID = problemState().selectedID;

      problemState().updateProblem(selectedID, {
        uncertain: blank ? true : false,
      });

      const problemJSON = problemJsonMap.get(selectedID);

      if (problemJSON) {
        data.valids?.forEach((agId: number) => {
          setAttemptGetterStatus({ problemJSON, agId, newStatus: 'ready' });
        });

        data.invalids?.forEach((agId: number) => {
          setAttemptGetterStatus({ problemJSON, agId, newStatus: 'invalid' });
        });
      }

      if (data.testResult) {
        const testResult = data.testResult;

        problemState().updateProblem(`${testResult.id}`, {
          seen: true,
          ready: testResult.status == 'ready',
          notValid: testResult.status == 'invalid',
        });

        if (!autoSubmittedDuringTest && testResult.status == 'ready') {
          returnFunctionForNextExamProblem = nextExamProblem;
        }
      } else if (!data.invalids && !autoSubmittedDuringTest)
        returnFunctionForNextExamProblem = nextExamProblem;
    }

    const m = data.m;

    if (m) {
      for (const messageKey in m) {
        if (typeof messageKey === 'string') {
          const message = m[messageKey];

          gmmAlert({
            msg: message,
            top: 'Message',
          });
        }
      }
    }

    if (penaltyMessage) {
      gmmAlert({
        msg: penaltyMessage,
        top: penaltyHeader,
      });
    }
  });

  return returnFunctionForNextExamProblem;
}

export function nextExamProblem(): void {
  let firstChoice: ExamProblem | undefined;
  let secondChoice: ExamProblem | undefined;
  let thirdChoice: ExamProblem | undefined;
  const { selectedID, problems } = problemState();
  const currentProblem = problems[selectedID] as ExamProblem;

  for (const key in problems) {
    const problem = problems[key] as ExamProblem;

    if (problem.id !== currentProblem.id && !problem.ready && !problem.locked) {
      if (!thirdChoice) thirdChoice = problem;
      if (problem.id > currentProblem.id && !secondChoice)
        secondChoice = problem;

      if (!firstChoice) firstChoice = problem;
      else {
        if (firstChoice.testNumber! > problem.testNumber!) {
          firstChoice = problem;
        }
      }
    }
  }

  if (firstChoice) examBoxClick(firstChoice.id);
  else if (secondChoice) examBoxClick(secondChoice.id);
  else if (thirdChoice) examBoxClick(thirdChoice.id);
}

export function setAttemptGetterStatus({
  problemJSON,
  agId,
  newStatus,
}: AttemptGetterStatus): void {
  problemJSON.r.forEach(row => {
    row.forEach(sup => {
      const attemptGetter = sup.answerPlusSupplier
        ? sup.answerPlusSupplier.ag.ag
        : sup.ag;

      if (attemptGetter && attemptGetter.agId === agId) {
        attemptGetter.s = newStatus;

        return;
      }
    });
  });
}

import axios from 'axios';

import { getApiUrl, getGuid, getSs, getUsername } from '../../stores/globalState';
import { consoleAxiosError, isAxiosError, toBody } from '../utils';

import {
  alreadyBlockedAFrame,
  getParsedMessage,
  isKnownIssue,
  isPossibleLoop,
  parseError,
} from './requesHandler';

export async function sendErrorToServer(msg: string, error?: Error): Promise<void> {
  try {
    const receivedError = error ? parseError(error) : undefined;

    if (isPossibleLoop(msg)) return;

    if (isKnownIssue(msg)) return;

    if (alreadyBlockedAFrame(msg)) return;

    if (!getUsername()) return;

    const data = {
      m: getParsedMessage(msg, receivedError),
      guid: getGuid(),
      ss: getSs(),
      a: new Date().getTime(),
    };

    const url = `${getApiUrl()}/ErrorToServer`;

    return await axios.post(url, toBody(data));
  } catch (err) {
    return isAxiosError(err) ? consoleAxiosError(err) : console.error({ err });
  }
}

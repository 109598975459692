import { sendObject } from './handler';

export type IncrementTimeSeen = {
  type: 'incrementTimeSeen';
  id: string;
  inc: number;
};

type Options = {
  problemId: string;
  increment: number;
};

export const postIncrementTimeSeen = async ({
  problemId,
  increment,
}: Options): Promise<void> =>
  sendObject({ type: 'incrementTimeSeen', id: problemId, inc: increment });

import { postGameFinished, postSaveGame } from '../api';
import { NO_MODAL, studentAppModalState } from '../stores/studentAppModalStore';
import { HighScores } from '../types/types';
import { harvestTimeSeen } from '../utils/gmmUtils';

import gameMaster from './gameengine';

export interface DisplayHighs {
  highsOrg: HighScores[];
  highsTchr: HighScores[];
}

function displayHighs(data?: DisplayHighs): void {
  if (!data || !data.highsTchr) return;

  // received high scores from server. Split by teacher and org.
  gameMaster.setHighScores(data.highsTchr, data.highsOrg);
}

// as in, time expired or user quit
export function gameEnded(name: string, save?: any): void {
  if (save) {
    const data = JSON.stringify(save);

    postSaveGame(name, data);
  }

  harvestTimeSeen();
  studentAppModalState().setCurrentModal(NO_MODAL);
}

// as in, won the game, or lost all lives
// time is still ticking, but for a fresh start
export function gameFinished(game: string, score: number): void {
  postGameFinished({ game, score }, displayHighs);
}

import axios from 'axios';

import {
  getApiUrl,
  getGuid,
  getSicId,
  getSs,
  getUsername,
} from '../../stores/globalState';
import { SendObjectBase } from '../sendObject';
import { isAxiosError, consoleAxiosError, toBody } from '../utils';
import { sendErrorToServer } from '../sendError';

interface SkipsEvent {
  id: string;
  skips: number;
  delta?: number;
}

/**
 * Tell the server when a student looks at a problem,
 * then clicks on a different Skill.
 */
export const sendSkipsUpdate = async ({
  id,
  skips,
  delta,
}: SendObjectBase<SkipsEvent>): Promise<void> => {
  try {
    const params = {
      id,
      skips,
      a: new Date().getTime(),
      ss: getSs(),
      user: getUsername(),
      guid: getGuid(),
      sicId: getSicId(),
      delta,
    };

    const url = `${getApiUrl()}/Skips`;

    return await axios.post(url, toBody(params));
  } catch (err) {
    if (isAxiosError(err)) {
      consoleAxiosError(err);

      return sendErrorToServer('skip Fail');
    }

    return;
  }
};
